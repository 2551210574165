<template>
  <section class="browse-our-network flex">
    <div v-if="showMap" class="map">
      <GmapMap
        :center="{ lat: 44.8174368, lng: 20.4159767 }"
        :zoom="3"
        :options="mapStyle"
        class="map"
      >
        <GmapMarker
          v-for="(m, index) in markers"
          :key="index"
          :position="m.position"
          :clickable="true"
          :icon="iconStyle"
          @click="goTo(m.name)"
        />
      </GmapMap>
    </div>
    <div v-if="!showMap"  class="container mx-auto pt-12 px-4">
      <div v-scroll-reveal class="container lg:w-1/2">
        <p class="uppercase font-light text-grey-100 -mb-2">/ NETWORK</p>
        <h1 class="text-3xl text-black font-bold uppercase mt-0 mb-4">
          {{ $t('browse_our_network') }}
        </h1>
        <p class="text-grey-100 text-light mb-12">{{ $t('browse_text') }}</p>
        <nuxt-link
          :to="localePath('local-information')"
          class="bg-blue-600 hover:bg-blue-700 px-12 uppercase hover:text-white text-white px-5 py-3"
        >
          {{ $t('browse_network') }}
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
import { style } from '~/static/mapstyle.js'

export default {
  ssr: false,
  data() {
    return {
      showMap: false,
      markers: [
        { name: 'SERBIA', position: { lat: 44.8174368, lng: 20.4159767 } },
        { name: 'ALBANIA', position: { lat: 41.319836, lng: 19.8177655 } },
        { name: 'BOSNIA', position: { lat: 43.8399129, lng: 18.316859 } },
        { name: 'CHINA', position: { lat: 22.5555518, lng: 113.7736832 } },
        { name: 'CROATIA', position: { lat: 45.3213896, lng: 14.4549911 } },
        { name: 'GERMANY', position: { lat: 48.0331452, lng: 11.58123 } },
        { name: 'MACEDONIA', position: { lat: 41.9960475, lng: 21.5451669 } },
        { name: 'MONTENEGRO', position: { lat: 42.552178, lng: 19.0941095 } },
        { name: 'RUSSIA', position: { lat: 45.0360167, lng: 37.8529585 } },
        { name: 'SLOVENIA', position: { lat: 45.9655182, lng: 14.6246908 } },
        { name: 'USA', position: { lat: 41.601261, lng: -87.5135366 } },
        { name: 'HUNGARY', position: { lat: 47.4987867, lng: 19.04348 } }
      ],
      mapStyle: { styles: style },
      iconStyle: {
        url: '/img/icons/marker2.png',
        scaledSize: { width: 32, height: 32, f: 'px', b: 'px' }
      }
    }
  },
  computed: {
    languagePrefix() {
      return this.$i18n.locale !== 'en' ? '/' + this.$i18n.locale : ''
    }
  },
  methods: {
    goTo(slug) {
      slug = slug.toLowerCase()
      // eslint-disable-next-line no-console
      // console.log(slug, this.$i18n)
      this.$router.push(`${this.languagePrefix}/local-information/${slug}`)
    }
  }
}
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
#map-wrap {
  height: 100%;
  width: 100%;
}
.map {
  width: 100vw;
  max-width: 100%;
  min-height: 60vh;
}
.browse-our-network {
  min-height: 60vh;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.3)
    ),
    url('../static/img/network_background_m.png');
  background-position: center;
  background-size: cover;
}
</style>
